import React, { useEffect, useState } from 'react';
import { fetchLakes } from '../services/inlandService';
import DisplayInlandData from './DisplayInlandData';
import DisplayStreamData from './DisplayStreamData';
import DisplayIndustrialData from './DisplayIndustrialData';

const SelectData: React.FC = () => {
  const [lakes, setLakes] = useState<string[]>([]);
  const [selectedLakes, setSelectedLakes] = useState<string[]>([]);
  const [selectedParameter, setSelectedParameter] = useState<string>('ph');
  const [selectedDataset, setSelectedDataset] = useState<string>(''); 
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [stationFilter, setStationFilter] = useState<string>('');
  const [companyFilter, setCompanyFilter] = useState<string>(''); 

  useEffect(() => {
    const loadLakes = async () => {
      try {
        const data = await fetchLakes();
        setLakes(data);
      } catch (error) {
        console.error("Error fetching lakes:", error);
      }
    };
    loadLakes();
  }, []);

  // Utility function to reset selection states
  const resetSelectionStates = () => {
    setStartDate('');
    setEndDate('');
    setErrorMessage('');
  };

  const handleCheckboxChange = (lake: string) => {
    setSelectedLakes((prevSelectedLakes) =>
      prevSelectedLakes.includes(lake)
        ? prevSelectedLakes.filter((selectedLake) => selectedLake !== lake)
        : [...prevSelectedLakes, lake]
    );
    resetSelectionStates();
  };

  const validateDateRange = (start: string, end: string) => {
    if (start && end && start > end) {
      setErrorMessage('Start date cannot be after end date.');
    } else {
      setErrorMessage('');
    }
  };

  const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newStartDate = event.target.value;
    setStartDate(newStartDate);
    validateDateRange(newStartDate, endDate);
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newEndDate = event.target.value;
    setEndDate(newEndDate);
    validateDateRange(startDate, newEndDate);
  };

  const handleDatasetChange = (dataset: string) => {
    setSelectedDataset(dataset);
    setSelectedLakes([]); // Clear selected lakes when changing datasets
    resetSelectionStates();
  };

  // Generalized input change handler
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === "stationFilter") {
      setStationFilter(value);
    } else if (name === "companyFilter") {
      setCompanyFilter(value);
    }
  };

  return (
    <div className="select-data-container" data-testid="select-data-component">
      <h2>Select Dataset</h2>
      <div className="button-container">
        <button
          onClick={() => handleDatasetChange("inland_lakes")}
          className="styled-button"
          data-testid="button-inland-lakes"
        >
          Inland Lakes Drinking Water <br /> Quality Data
        </button>
        <button
          onClick={() => handleDatasetChange("stream_water")}
          className="styled-button"
          data-testid="button-stream-water"
        >
          Stream Water <br /> Quality Data
        </button>
        <button
          onClick={() => handleDatasetChange("industrial_water")}
          className="styled-button"
          data-testid="button-industrial-water"
        >
          Industrial Wastewater <br /> Data
        </button>
      </div>

      {selectedDataset === "inland_lakes" && (
        <div
          className="inland-selection-container"
          data-testid="inland-selection-container"
        >
          <div className="selection-box lake-selection">
            <h3>Select Inland Lakes</h3>
            <div className="checkbox-list" data-testid="checkbox-list">
              {lakes.map((lake, index) => (
                <label key={index} className="checkbox-label">
                  <input
                    type="checkbox"
                    value={lake}
                    checked={selectedLakes.includes(lake)}
                    onChange={() => handleCheckboxChange(lake)}
                    className="checkbox-input"
                    data-testid={`lake-select-${lake}`} // Added data-testid for lake checkboxes
                  />
                  {lake}
                </label>
              ))}
            </div>
          </div>

          <div className="selection-box parameter-selection">
            <h3>Select Parameter</h3>
            <select
              id="parameter-select"
              value={selectedParameter}
              onChange={(e) => setSelectedParameter(e.target.value)}
              className="styled-select"
              disabled={selectedLakes.length === 0}
              data-testid="parameter-select"
            >
              <option value="ph">pH Level</option>
              <option value="gran_alkalinity_mg_l">
                Gran Alkalinity (mg/L)
              </option>
              <option value="tn_mg_l">Total Nitrogen (mg/L)</option>
              <option value="tp_ug_l">Total Phosphorus (μg/L)</option>
            </select>
          </div>

          <div className="selection-box date-selection">
            <h3>Select Date Range</h3>
            <label htmlFor="start-date" className="styled-label">
              Start:
            </label>
            <input
              type="date"
              id="start-date"
              value={startDate}
              onChange={handleStartDateChange}
              className="styled-input date-input"
              disabled={selectedLakes.length === 0}
              data-testid="start-date"
            />
            <label htmlFor="end-date" className="styled-label">
              End:
            </label>
            <input
              type="date"
              id="end-date"
              value={endDate}
              onChange={handleEndDateChange}
              className="styled-input date-input"
              disabled={selectedLakes.length === 0}
              data-testid="end-date"
            />
          </div>

          {selectedLakes.length > 0 && (
            <DisplayInlandData
              selectedLakes={selectedLakes}
              selectedParameter={selectedParameter}
              startDate={startDate}
              endDate={endDate}
            />
          )}
        </div>
      )}

{selectedDataset === "stream_water" && (
  <div
    className="stream-water-container"
    data-testid="stream-water-container"
  >
    <h2>Stream Water Quality Data</h2>

    <div className="filters-row">
      {/* Station Filter Input */}
      <div className="selection-box station-filter">
        <label htmlFor="station-filter" className="styled-label">
          Filter by Station:
        </label>
        <input
          type="text"
          id="station-filter"
          value={stationFilter}
          onChange={handleInputChange}
          className="station-filter-input styled-input"
          data-testid="station-filter-stream"
        />
      </div>

      {/* Date Range Input */}
      <div className="selection-box date-selection">
      <h3>Select Date Range</h3>
        <label htmlFor="start-date" className="styled-label">
          Start Date:
        </label>
        <input
          type="date"
          id="start-date"
          value={startDate}
          onChange={handleStartDateChange}
          className="date-input styled-input"
          data-testid="start-date-stream"
        />
        <label htmlFor="end-date" className="styled-label">
          End Date:
        </label>
        <input
          type="date"
          id="end-date"
          value={endDate}
          onChange={handleEndDateChange}
          className="date-input styled-input"
          data-testid="end-date-stream"
        />
      </div>
    </div>

    {/* DisplayStreamData Component */}
    <DisplayStreamData
      stationFilter={stationFilter}
      startDate={startDate}
      endDate={endDate}
    />
  </div>
)}


      {selectedDataset === "industrial_water" && (
        <div data-testid="industrial-water-container">
          <h2>Industrial Wastewater Data</h2>
          <label htmlFor="companyFilter" className="section-label">Filter by Company: </label>
          <input
            type="text"
            id="companyFilter"
            name="companyFilter"
            value={companyFilter}
            onChange={handleInputChange}
            className="dropdown-select"
            placeholder="Enter Company Name"
            data-testid="company-filter-input"
          />
          <DisplayIndustrialData companyFilter={companyFilter} />
        </div>
      )}

      {errorMessage && (
        <p className="error-message" data-testid="error-message">
          {errorMessage}
        </p>
      )}
    </div>
  );
};

export default SelectData;
