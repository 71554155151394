import React, { useEffect, useState } from "react";
import { fetchStreamData } from "../services/streamService";
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  TooltipProps,
  ResponsiveContainer,
} from "recharts";

interface StreamData {
  station: string;
  parameter: string;
  result: number;
  date: string;
}

interface DisplayStreamDataProps {
  stationFilter: string;
  startDate: string;
  endDate: string;
}

export const CustomTooltip: React.FC<TooltipProps<number, string>> = ({ active, payload }) => {
  if (active && payload && payload.length > 0) {
    const data = payload[0].payload;
    return (
      <div style={{ backgroundColor: 'white', border: '1px solid #ccc', padding: '10px' }}>
        <p>Date: <span style={{ color: '#1E90FF' }}>{data.date}</span></p>
        <p>Result: <span style={{ color: '#1E90FF' }}>{data.result}</span></p>
        <p>Sample: <span style={{ color: '#1E90FF' }}>{data.parameter}</span></p>
        <p>Station: <span style={{ color: '#1E90FF' }}>{data.station}</span></p>
      </div>
    );
  }
  return null;
};

const DisplayStreamData: React.FC<DisplayStreamDataProps> = ({
  stationFilter,
  startDate,
  endDate,
}) => {
  const [filteredData, setFilteredData] = useState<StreamData[]>([]);
  const [error, setError] = useState<string>("");
  const [page, setPage] = useState<number>(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch the data based on pagination and date range
        const streamData = await fetchStreamData(page, startDate, endDate);

        // Apply the station filter here after fetching data
        const filtered = stationFilter
          ? streamData.filter((item: StreamData) =>
              item.station.toLowerCase().includes(stationFilter.toLowerCase()),
            )
          : streamData;

        // Set the filtered data
        setFilteredData(filtered);
      } catch (err) {
        setError("Failed to load data. Please try again.");
        console.error(err);
      }
    };

    fetchData();
  }, [startDate, endDate, stationFilter, page]); // All dependencies are included here

  return (
    <div>
      {error && (
        <p data-testid="error-message" style={{ color: "red" }}>
          {error}
        </p>
      )}
      <div>
        {filteredData.length > 0 ? (
          <ResponsiveContainer width="100%" height={400}>
            <ScatterChart margin={{ top: 20, right: 30, left: 20, bottom: 60 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                name="Date"
                angle={-45}
                dy={30}
                dx={-30}
                label={{ value: "Date", position: "insideBottom", offset: -60}}
              />
              <YAxis
                dataKey="result"
                name="Result"
                label={{
                  value: "Result Per Sample",
                  angle: -90,
                  position: "insideLeft",
                }}
              />
              <Tooltip
                content={<CustomTooltip />}
                cursor={{ strokeDasharray: "3 3" }}
              />
              <Scatter
                name="Result by Date"
                data={filteredData}
                fill="#1E90FF"
              />
            </ScatterChart>
          </ResponsiveContainer>
        ) : (
          <p>No data available for the selected station.</p>
        )}
      </div>

      <table>
        <thead>
          <tr>
            <th>Station</th>
            <th>Sample</th>
            <th>Result</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((item) => (
            <tr key={`${item.station}-${item.date}`}>
              <td>{item.station}</td>
              <td>{item.parameter}</td>
              <td>{item.result}</td>
              <td>{item.date}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div>
        <button
          onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
          disabled={page === 0}
        >
          Previous
        </button>
        <button onClick={() => setPage((prevPage) => prevPage + 1)}>
          Next
        </button>
      </div>
    </div>
  );
};

export default DisplayStreamData;
