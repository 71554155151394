import React from "react";
import SelectData from "./components/SelectData";

const App: React.FC = () => {
  return (
    <div className="App">
      <header className="header">
      <img src="/waterwise-logo.png" alt="Water Wise Logo" className="logo" />
        <h1>Water Wise</h1>
      </header>
      <main className="main-content">
        <section className="product-pitch">
          <h2>Protect Our Water Resources</h2>
          <p>
            Water pollution threatens both ecosystems and public health, with
            industrial wastewater being one of the major contributors. WaterWise
            offers a game-changing solution by combining water quality data from
            streams and lakes with industrial wastewater records.
          </p>
          <p>
            This powerful tool allows agencies, researchers, and policymakers to
            visualize trends, identify pollution hotspots, and take action before
            it&apos;s too late.
          </p>
          <p>
            With WaterWise, you&apos;ll gain access to real-time insights, ensuring you
            stay ahead of environmental issues and regulatory demands. Whether you&apos;re
            creating policies, conducting research, or ensuring industrial compliance,
            this tool provides the data and visualizations you need to make a difference.
          </p>
          <p><strong>Get ahead of water pollution&mdash;invest in WaterWise today and protect our vital water resources!</strong></p>

        </section>

        {/* Add the SelectData component here */}
        <section className="select-station">
          <SelectData />
        </section>

      </main>
    </div>
  );
};

export default App;
