import React, { useEffect, useState } from 'react';
import { fetchFilteredData } from '../services/inlandService';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { InlandData } from '../types/DataTypes';

const COLORS = [
  "#1E90FF",
  "#FF6347",
  "#32CD32",
  "#FFD700",
  "#8A2BE2",
  "#FF1493",
  "#00CED1",
  "#FF8C00",
  "#7FFF00"
];

// Define CustomTooltip outside of the main component
export const CustomTooltip: React.FC<{ active?: boolean; payload?: any; label?: any }> = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const formattedDate = new Date(label).toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });

    return (
      <div data-testid="custom-tooltip" className="custom-tooltip" style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '10px' }}>
        <p>{`date: ${formattedDate}`}</p>
        {payload.map((entry: any, index: number) => (
          <p key={`item-${index}`} style={{ color: COLORS[index % COLORS.length] }}>
            {`${entry.name} : ${entry.value}`}
          </p>
        ))}
      </div>
    );
  }
  return null;
};

const DisplayInlandData: React.FC<{ selectedLakes: string[], selectedParameter: string, startDate: string, endDate: string }> = ({ selectedLakes, selectedParameter, startDate, endDate }) => {
  const [data, setData] = useState<InlandData[]>([]);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    const loadData = async () => {
      try {
        const fetchedData = await Promise.all(
          selectedLakes.map((lake, index) =>
            fetchFilteredData(lake, selectedParameter, startDate, endDate)
              .then((lakeData) => lakeData.map((dataItem: InlandData) => ({
                ...dataItem,
                lake: selectedLakes[index],
                sdate: new Date(dataItem.sdate),
              })))
          )
        );

        const combinedData = fetchedData
          .flat()
          .sort((a, b) => (a.sdate as Date).getTime() - (b.sdate as Date).getTime());

        setData(combinedData);
      } catch (err) {
        setError("failed to load data. please try again.");
        console.error(err);
      }
    };

    loadData();
  }, [selectedLakes, selectedParameter, startDate, endDate]);

  const minDate = data.length > 0 ? (data[0].sdate as unknown as Date).getTime() : new Date(startDate).getTime();
  const maxDate = data.length > 0 ? (data[data.length - 1].sdate as unknown as Date).getTime() : new Date(endDate).getTime();

  return (
    <div>
      <h2 data-testid="chart-title">Water Quality Comparison</h2>
      {error && <p data-testid="error-message" style={{ color: 'red' }}>{error}</p>}
      <ResponsiveContainer width="100%" height={400}>
        <LineChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 50 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="sdate"
            type="number"
            domain={[minDate, maxDate]}
            tickFormatter={(tick) => new Date(tick).toISOString().split('T')[0]}
            angle={-45}
            textAnchor="end"
          />
          <YAxis label={{ value: selectedParameter, angle: -90, position: 'insideLeft' }} />
          <Tooltip content={<CustomTooltip />} />
          <Legend
            layout="horizontal"
            verticalAlign="top"
            formatter={(value, entry, index) => (
              <span style={{ color: COLORS[index % COLORS.length] }}>{value}</span>
            )}
          />
          {selectedLakes.map((lake, index) => (
            <Line
              key={lake}
              type="monotone"
              dataKey={selectedParameter}
              data={data.filter((d) => d.lake === lake)}
              name={lake}
              stroke={COLORS[index % COLORS.length]}
              activeDot={{ r: 8 }}
              data-testid={`line-${lake}`}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default DisplayInlandData;
