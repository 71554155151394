// src/services/inlandService.ts
export const fetchLakes = async (): Promise<string[]> => {
  const response = await fetch('/api/inland/lakes');
  if (!response.ok) {
    throw new Error("Failed to fetch lakes");
  }
  return response.json();
};

export const fetchData = async (lake: string, startDate?: string, endDate?: string) => {
  try {
    const url = new URL('/api/inland/data', window.location.origin);
    url.searchParams.append('lake', lake);
    
    if (startDate) {
      url.searchParams.append('startDate', startDate);
    }
    
    if (endDate) {
      url.searchParams.append('endDate', endDate);
    }

    const response = await fetch(url.toString());

    if (!response.ok) {
      throw new Error(`Error fetching data: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const fetchFilteredData = async (lake: string, parameter: string, startDate?: string, endDate?: string) => {
  try {
    const url = new URL('/api/inland/parameter', window.location.origin);
    url.searchParams.append('lake', lake);
    url.searchParams.append('parameter', parameter);
    
    if (startDate) {
      url.searchParams.append('startDate', startDate);
    }
    
    if (endDate) {
      url.searchParams.append('endDate', endDate);
    }

    const response = await fetch(url.toString());

    if (!response.ok) {
      throw new Error(`Error fetching data: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching filtered data:", error);
    throw error;
  }
};


