import { StreamData } from "../types/DataTypes";

export const fetchStreamData = async (
  page: number,
  startDate?: string,
  endDate?: string,
): Promise<StreamData[]> => {
  try {
    // Construct the base URL
    const url = new URL(
      startDate && endDate
        ? "/api/stream_data/parameter" // Use filtered endpoint
        : "/api/stream_data", // Use base endpoint if no dates
      window.location.origin,
    );

    // Add startDate and endDate parameters if provided
    if (startDate) {
      url.searchParams.append("startDate", startDate);
    }
    if (endDate) {
      url.searchParams.append("endDate", endDate);
    }
    
    // Add pagination parameters
    url.searchParams.append("page", page.toString());
    url.searchParams.append("size", "100");

    // Fetch data
    const response = await fetch(url.toString());
    if (!response.ok) {
      throw new Error("Failed to fetch stream data");
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
