import React, { useEffect, useState } from "react";
import { fetchIndustrialData } from "../services/industrialService";
import { IndustrialData } from "../types/DataTypes";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  TooltipProps,
  ResponsiveContainer,
} from "recharts";

interface IndustrialDataProps {
  companyFilter: string;
}

const CustomTooltip: React.FC<TooltipProps<number, string>> = ({ active, payload }) => {
  if (active && payload && payload.length > 0) {
    const data = payload[0].payload;
    return (
      <div style={{ backgroundColor: 'white', border: '1px solid #ccc', padding: '10px' }}>
        <p>Company: <span style={{ color: '#1E90FF' }}>{data.worksName}</span></p>
        <p>Parameter: <span style={{ color: '#1E90FF' }}>{data.parameter}</span></p>
        <p>Value: <span style={{ color: '#1E90FF' }}>{data.value}</span></p>
        <p>Date: <span style={{ color: '#1E90FF' }}>{data.date}</span></p>
      </div>
    );
  }
  return null;
};

const DisplayIndustrialData: React.FC<IndustrialDataProps> = ({ companyFilter }) => {
  const [data, setData] = useState<IndustrialData[]>([]);
  const [filteredData, setFilteredData] = useState<IndustrialData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState<number>(0);

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const fetchedData = await fetchIndustrialData(page);
        setData(fetchedData);
      } catch (err) {
        setError("Failed to load data. Please try again.");
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [page]);

  useEffect(() => {
    setFilteredData(
      companyFilter
        ? data.filter((item) =>
            item.worksName.toLowerCase().includes(companyFilter.toLowerCase())
          )
        : data
    );
  }, [companyFilter, data]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div style={{ color: "red" }}>{error}</div>;

  return (
    <div>
      {/* Graph */}
      <div>
        {filteredData.length > 0 ? (
          <ResponsiveContainer width="100%" height={400}>
            <BarChart data={filteredData} margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" label={{ value: "Date", position: "insideBottom", offset: -10 }} />
              <YAxis label={{ value: "Value", angle: -90, position: "insideLeft" }} />
              <Tooltip content={<CustomTooltip />} />
              <Bar dataKey="value" fill="#1E90FF" />
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <p>No data available for the selected company.</p>
        )}
      </div>

      {/* Table */}
      <table>
        <thead>
          <tr>
            <th>Company</th>
            <th>Parameter</th>
            <th>Value</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((row, index) => (
            <tr key={`${row.worksName}-${index}`}>
              <td data-testid={`company-${row.worksName}`}>{row.worksName}</td>
              <td data-testid={`parameter-${row.parameter}`}>{row.parameter}</td>
              <td data-testid={`value-${row.value}`}>{row.value}</td>
              <td data-testid={`date-${row.date}`}>{row.date}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination Controls */}
      <div>
        <button onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))} disabled={page === 0}>
          Previous
        </button>
        <button onClick={() => setPage((prevPage) => prevPage + 1)}>
          Next
        </button>
      </div>
    </div>
  );
};

export { CustomTooltip }; // Named export for CustomTooltip for testing
export default DisplayIndustrialData;
